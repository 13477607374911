import React from "react";
import InputBox from "./InputBox";
import PropTypes from "prop-types";
import {
  KtmEventDataList,
  FootballEventDataList,
  TreasureRoadEventDataList,
  KtmV4EventDataList,
  KtmV5EventDataList,
  TreasureRoadV2EventDataList,
  InfiniteKtmEventDataList,
} from "../data/parchisi/EventData";
import {
  LS_KtmV3EventDataList,
  LS_TreasureRoadEventDataList,
  LS_KtmV4EventDataList,
} from "../data/ludo/EventData";
import { makeStyles } from "tss-react/mui";
import {
  Grid,
  Paper,
  Typography,
  Button,
  Divider,
  CircularProgress,
} from "@mui/material";
import { GetAppType, AppType } from "../globals/conf.ts";
import { get as lodashGet } from "lodash";

const useStyles = makeStyles()((theme) => ({
  eventInput: {
    maxHeight: "512px",
    overflowY: "scroll",
    padding: theme.spacing(1),
  },
  wrapper: {
    padding: theme.spacing(2),
  },
  header: {
    display: "flex",
    marginBottom: theme.spacing(1),
    alignItems: "center",
  },
  footer: {
    display: "flex",
    marginTop: theme.spacing(1),
    alignItems: "center",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

export default function EventDataInput(props) {
  const { classes } = useStyles();

  const getList = () => {
    var list;
    let isParchisi = GetAppType() == AppType.Parchisi;
    if (props.eventType == 0) {
      list = KtmEventDataList;
    } else if (props.eventType == 1) {
      list = LS_KtmV3EventDataList;
    } else if (props.eventType == 2) {
      list = FootballEventDataList;
    } else if (props.eventType == 3) {
      list = isParchisi
        ? TreasureRoadEventDataList
        : LS_TreasureRoadEventDataList;
    } else if (props.eventType == 4) {
      list = isParchisi ? KtmV4EventDataList : LS_KtmV4EventDataList;
    } else if (props.eventType == 5) {
      list = KtmV5EventDataList;
    } else if (props.eventType == 6) {
      list = TreasureRoadV2EventDataList;
    } else if (props.eventType == 7) {
      list = InfiniteKtmEventDataList;
    }
    return list.map((option, index) => {
      return (
        <Grid key={index} item sm={12} lg={12}>
          <InputBox
            option={option}
            value={
              option.name === ""
                ? props.eventData
                : lodashGet(props.eventData, option.name)
            }
            handleChange={props.handleEventDataChange}
            addTreasureReward={props.addTreasureReward}
          />
        </Grid>
      );
    });
  };

  return (
    <Paper className={classes.wrapper}>
      <Grid container spacing={2}>
        <Grid item className={classes.header} sm={12}>
          <Typography variant="h6">Event Details</Typography>
          <div style={{ flexGrow: 1 }} />
        </Grid>
        <Divider light={false}></Divider>
        <Grid container className={classes.eventInput} spacing={2}>
          {getList()}
        </Grid>
        <Grid item className={classes.footer} sm={12}>
          <Button
            color="primary"
            variant="contained"
            disabled={props.loading}
            onClick={props.setEventDetails}
          >
            Update
            {props.loading && (
              <CircularProgress size={24} className={classes.buttonProgress} />
            )}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}

EventDataInput.propTypes = {
  children: PropTypes.node,
};
